<template>
  <div />
</template>

<script>
export default {
  name: "Logout",
  created() {
    window.localStorage.removeItem("closeModal");
    this.$msal.signOut();
    this.$router.push({ name: "Login" });
  },
};
</script>
